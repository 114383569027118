
.allBg{
  background-color: #2c2928;
  font-family: Arial, Helvetica, sans-serif;
  color:white;
  padding-top:50px;
  
}

.wrap {
  align-items: center;
  justify-content: center;
  display: flex; /*水平並列*/
  flex-wrap: wrap;
  gap:15px;

}

.item {
  padding: 8px;
  width: 20%;
  background-color: white;
  border-radius: 10px;
}

.CardImg{
  width:100%;
}
.CardName{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  padding-top:5px;
  text-align: center;
  color:black;
}

.detail-wrap{
  
  
  display: flex; /*水平並列*/
  justify-content: center;
  gap:15px;

}

.detail-word{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  padding-top:15px;
  text-align: left;
  
}

.detail-item .detail-CardImg{
  padding: 15px;
  width: 90%;
  background-color: white;
  border-radius: 10px;

}

.detail-item {
  padding: 8px;
  width: 20%;

  border-radius: 10px;
  
}

.card-container{
  position:relative;
 
}
/*.card-container:hover .cover{
  transform:rotateY(0deg)
}
.card-container:hover .back{
  transform:rotateY(180deg)
}*/
@keyframes example1{
  from {transform:rotateY(-180deg);}
  to { transform:rotateY(0deg);}
  
}
@keyframes example2{
  from {transform:rotateY(0deg);}
  to { transform:rotateY(180deg);}
}
.cover, .back{
  position:absolute;
  width:100%;
  height: 100%;
  backface-visibility: hidden;
  transition: transform .25s ease-in-out;
}
.cover{
  transform:rotateY(-180deg);
  animation-name: example1;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
.back{
  transform:rotateY(0deg);
  animation-name: example2;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.nav {
  background-color: #333;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.site-title {
  font-size: 2rem;
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.nav li.active {
  background-color: #555;
}

.nav li:hover {
  background-color: #777;
}